/* CSS for foreninger */
@import "../../../../../../lib/uio1/profile/css/util.less";

// UU
#hidnav a[href="#bottomnav"] {
  display: none;
}

.uio-app-line-top {
  height: 68px;
  padding: 20px 35px;
  .uio-logo {
    background: url(/vrtx/dist/resources/x1/images/uio-logo-name-no-neg.svg) no-repeat left center;
    display: block;
    height: 28px;
    background-size: contain;
    a {
      background: none;
    }
  }
}

.not-for-ansatte {
  #head-wrapper {
    background: @color-light;
    border-bottom: 2px solid @color-lines;
    .uio-app-name {
      display: none;
    }
    #head {
      .head-menu {
        > .vrtx-login-manage-component > a,
        > ul > li a {
          color: @color-neutral--dark;
        }
        .vrtx-login-manage-component {
          .vrtx-dropdown-link {
            background: transparent
              url("../uio1/images/head-dropdown-toggle-black.png") no-repeat
              scroll 100% 53%;
          }
        }
        .language a,
        .vrtx-login-manage-component a {
          color: @color-neutral--dark;
        }
      }
      #header {
        height: 100px;
        width: 100%;
        position: absolute;
        color: @color-header-logo;
        .logo {
          white-space: nowrap;
          font: bold 2.77em Arial, serif;
          top: 30px;
          position: absolute;
          padding: 2px 5px 5px 0;
          a {
            color: @color-header-logo;
            &:hover,
            &:focus {
              text-decoration: underline;
            }
          }
        }
        .logo-descr {
          font: normal 1em Arial, serif;
          line-height: 1em;
          top: 100px;
          position: absolute;
          color: @color-neutral--dark;
        }
      }
      .header-search input[type="text"] {
        background: @color-text-field;
        color: @color-neutral--dark;
      }
    }
  }

  // Arrows
  a.vrtx-more::before,
  div.vrtx-more a::before,
  a.all-messages::before,
  a.all-comments::before,
  .footer > .vrtx-search-scope a::before,
  a.more-url::before {
    background: url("../images/arrow-forward.svg") no-repeat 0 3px !important;
  }
  #main .vrtx-back a::before {
    background: url("../images/icon-chevron-back.svg") no-repeat 0 0.2ex;
    background-size: auto 100%;
    content: " ";
  }
  .ui-accordion.ui-widget {
    .ui-accordion-header .ui-icon {
      background: transparent url("../images/icon-chevron-right.svg") no-repeat
        center left;
    }
    .ui-accordion-header.ui-accordion-header-active .ui-icon {
      background-image: url("../images/icon-chevron-down.svg");
    }
  }

  #bottomnav {
    display: none;
  }

  #footer-wrapper {
    background: @color-neutral--light;
    #footers {
      background: url("../images/uiologo.png") no-repeat 0 15px;
      #privacy-notification {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 15px;
        background: black;
        z-index: 1;
        div {
          display: flex;
          justify-content: center;
          align-items: baseline;
        }
        button {
          flex-shrink: 0;
          margin-left: 20px;
          width: auto;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    *,
    a {
      color: @color-neutral--dark;
    }
    .responsible-login .content {
      margin-left: 40px;
    }

    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: transparent url("../uio1/images/dropdown-black.png") no-repeat
        scroll right center / 22px auto;
      &:focus,
      &:hover {
        background-image: url("../uio1/images/dropdown-black-hover.png");
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {    
  #head-wrapper {
    .uio-app-line-top {
      height: 68px;
      .uio-logo {
        a {
          background: none;
        }
      }
    }
    #head {
      height: 200px;
      #header {
        .logo a {
          font-size: 32px;
          top: 0px;
          position: absolute;
          left: 14px;
        }
        .logo-descr {
          line-height: 2rem;
          top: 80px;
          left: 14px;
        }
      }
    }
  }
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Neutral colors
@color-neutral--dark: #444444;
@color-neutral: #4f4f4f;
@color-neutral--light: #f4f4f4;
@color-lines: #efefef;

// Suplementary colors
@color-light: #ffffff;
@color-text-field: #f3f3f3;
@color-header-logo: #96948e;
